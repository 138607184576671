<template>
	<div>
		<!-- dome 文件 后期删除 -->
		<!-- <TUICallKit></TUICallKit> -->
	</div>
</template>

<script   setup>
	import { TUICallEngine, TUICallEvent } from "tuicall-engine-webrtc"
	
	let options = {
	  SDKAppID: 1400759933, // 接入时需要将0替换为您的云通信应用的 SDKAppID
	  // tim:  // tim 参数适用于业务中已存在 TIM 实例，为保证 TIM 实例唯一性
	};
	
	let tuicallEngine = TUICallEngine.createInstance(options);
</script>

<style>
</style>